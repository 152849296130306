// export const environment = {
//   usersServiceApi: 'https://users.api.hospitalpassport.com/api/v1/',
//   dataServiceApi: 'https://main.api.hospitalpassport.com/api/v1/',
//   targetTenant: 'main-jnzf8',
//   title: 'Hospital Passport',
//   showRegisterDoBField: false,
//   production: true,
//   stripeKey: 'pk_test_51IviylGsOnJR7olq1EubRnSsojA5Lf2IyWAJAIn9f5GBhuWS64MousXmHIOlXYuwfbD3msELpDfUJNJeAIyUTHiT003mEaAgbY'
// };

// export const suvoUsersClientLibConfig = {
//   usersServiceApi: 'https://users.api.hospitalpassport.com/api/v1/',
//   dataServiceApi: 'https://main.api.hospitalpassport.com/api/v1/',
//   targetTenant: 'main-jnzf8',
//   title: 'Hospital Passport',
//   showRegisterDoBField: false
// };

// export const firebaseConfig = {
//   apiKey: 'AIzaSyCt3ZgNC2tBU7r8YEmd3fIZr4p3l_Ytans',
//   authDomain: 'hp-users-identity-prod.firebaseapp.com'
// };

// // SWAP FOR LOCAL DEV ///////
// export const environment = {
//   usersServiceApi: 'http://localhost:3000/api/v1/',
//   dataServiceApi: 'http://localhost:8080/api/v1/',
//   showRegisterDoBField: false,
//   targetTenant: 'main-cqxpv',
//   title: 'Hospital Passport',
//   production: true,
//   stripeKey: 'pk_test_51IviylGsOnJR7olq1EubRnSsojA5Lf2IyWAJAIn9f5GBhuWS64MousXmHIOlXYuwfbD3msELpDfUJNJeAIyUTHiT003mEaAgbY'
// };

// export const suvoUsersClientLibConfig = {
//   usersServiceApi: 'http://localhost:3000/api/v1/',
//   dataServiceApi: 'http://localhost:8080/api/v1/',
//   showRegisterDoBField: false,
//   targetTenant: 'main-cqxpv',
//   title: 'Hospital Passport',
//   production: true
// };

// export const firebaseConfig = {
//   apiKey: 'AIzaSyAhJjRBpepZ3HHPKUeCjJ08ClLK7OUu5UQ',
//   authDomain: 'hp-users-identity-test.firebaseapp.com'
// };
/////////////////////////////


// SWAP FOR LOCAL DEV ///////
export const environment = {
  usersServiceApi: 'https://users.api.hospitalpassport-develop.com/api/v1/',
  dataServiceApi: 'https://main.api.hospitalpassport-develop.com/api/v1/',
  showRegisterDoBField: false,
  targetTenant: 'main-cqxpv',
  title: 'Hospital Passport',
  production: true,
  stripeKey: 'pk_test_51IviylGsOnJR7olq1EubRnSsojA5Lf2IyWAJAIn9f5GBhuWS64MousXmHIOlXYuwfbD3msELpDfUJNJeAIyUTHiT003mEaAgbY'
};

export const suvoUsersClientLibConfig = {
  usersServiceApi: 'https://users.api.hospitalpassport-develop.com/api/v1/',
  dataServiceApi: 'https://main.api.hospitalpassport-develop.com/api/v1/',
  showRegisterDoBField: false,
  targetTenant: 'main-cqxpv',
  title: 'Hospital Passport',
  production: true
};

export const firebaseConfig = {
  apiKey: 'AIzaSyAhJjRBpepZ3HHPKUeCjJ08ClLK7OUu5UQ',
  authDomain: 'hp-users-identity-test.firebaseapp.com'
};
/////////////////////////////


// export const firebaseUiAuthConfig: firebaseui.auth.Config = {
//   signInFlow: 'popup',
//   signInOptions: [
//     {
//       provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
//       disableSignUp: {
//         status: true
//       }
//     }
//   ],
//   tosUrl: 'http://cdn.hospital-passport-portal.s3-website-eu-west-1.amazonaws.com/private/dashboard'
// };



